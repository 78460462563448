import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "'Max', sans-serif",
    body: "'Noto Sans', sans-serif",
  },
  colors: {
    ahuaBlue: {
      0: "#E6F2FA",
      5: "#D2E9F9",
      10: "#BCDFF8",
      20: "#82C5F4",
      30: "#55AEED",
      40: "#2F95E5",
      50: "#137ED9",
      60: "#0068CA",
      70: "#0058B7",
      80: "#0047A0",
      90: "#003686",
      100: "#00276B",
    },
    ahuaGreen: {
      0: "#E8F7DA",
      5: "#DBF7C6",
      10: "#CCF0AD",
      20: "#ACEB7C",
      30: "#93D65E",
      40: "#75C738",
      50: "#51B816",
      60: "#46A314",
      70: "#359900",
      80: "#2C8F00",
      90: "#207500",
      100: "#E6F2FA",
    },
  },
})

export default theme
